<template>
	<el-dialog title="导出搜索结果" :close-on-click-modal="false" width="300px" :visible.sync="is_show_in_page">
		<div style="text-align:center">总数量:{{max}} / 已读取:{{readed}}</div>
	</el-dialog>
</template>
<script>
import xlsx from "xlsx";
	export default {
		props:{
			is_show:Number,//是否显示
			rull:Object,//搜索条件
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
				
				//单次读取数量
				num:1000,

				//总数量
				max:0,

				//已读取
				readed:0,

				//上次最后一个id
				id_max:false,

				p:1,

				xlsx_list:[]
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.max=0
					this.readed=0
					this.id_max=false
					this.start()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {		
			//生成csv
			make_csv(){
					const _data = [
					// 代表 导出 列标题
					[
					"运单id",
					"上游编号",
					"运单编号",
					"线路编号",
					"组编号",
					"发货时间",
					"运单生成时间",
					"发车时间",
					"到货时间",
					"支付完成时间",
					"开票完成时间",
					"收款人电话",
					"收款人姓名",
					"司机电话",
					"司机姓名",
					"司机身份证号码",
					"发货地省份",
					"发货地城市",
					"发货地县区",
					"发货地详细地址",
					"装货联系人",
					"装货联系电话",
					"货物名称",
					"到货地省份",
					"到货地城市",
					"到货地县区",
					"到货地详细地址",
					"到货地联系人",
					"到货地联系电话",
					"货物类型",
					"运输距离",
					"运费",
					"服务费",
					"结算给车队长",
					"结算给司机",
					"已支付",
					"已结(车队长)",
					"已结(司机)",
					"应付总服务费",
					"结算完成时间",
					"运单上报时间",
					"出金上报时间",
					"匹配方式",
					"运单状态",
					"锁定状态",
					"支付状态",
					"开票状态",
					"结算状态",
					"部SDK上报状态",
					"省监管上报状态",
					"省监管上报节点",
					"结算方式",
					"是否回程",
					"货主电话",
					"货主姓名",
					"货主公司",
					"车牌号",
					"发车重量(吨)",
					"到货重量(吨)",
					"货物体积(方)",
					"磅单编号",
					"已付总服务费",
					"可开票总金额(元)",
					"运费分配方式",
					"计算方式",
					"已付(含服务费)",
					"已开票(含服务费)",
					"已开票(不含服务费)",
					"备注",
					"发票备注",
					"公司利润",
					"保障服务费",
					"货物金额",
					],
				];
				this.xlsx_list.forEach((item) => {
					_data.push(Object.values(item));
				});
				const ws = xlsx.utils.aoa_to_sheet(_data);
				const wb = xlsx.utils.book_new();
				xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
				xlsx.writeFile(wb, "运单列表.xlsx");
				this.xlsx_list = []
				// var shunxu=[
				// 	{key:"id",cname:"运单id"},
				// 	{key:"out_trade_num",cname:"上游编号"},
				// 	{key:"truck_tord_num",cname:"运单编号"},
				// 	{key:"bl_num",cname:"线路编号"},
				// 	{key:"group1_num",cname:"组编号"},
				// 	{key:"cargo_creat_time_text",cname:"发货时间"},
				// 	{key:"creat_time_text",cname:"运单生成时间"},
				// 	{key:"start_time_text",cname:"发车时间"},
				// 	{key:"arrived_time_text",cname:"到货时间"},
				// 	{key:"payed_time_text",cname:"支付完成时间"},
				// 	{key:"invoiced_time_text",cname:"开票完成时间"}, 
				// 	{key:"truck_owner_tel",cname:"收款人电话"},
				// 	{key:"truck_owner_name",cname:"收款人姓名"},
				// 	{key:"driver_tel",cname:"司机电话"},
				// 	{key:"driver_name",cname:"司机姓名"},
				// 	{key:"id_card_num",cname:"司机身份证号码"},
				// 	{key:"case_prov",cname:"发货地省份"},
				// 	{key:"case_city",cname:"发货地城市"},
				// 	{key:"case_county",cname:"发货地县区"},
				// 	{key:"case_addr_info",cname:"发货地详细地址"},
				// 	{key:"case_link_man",cname:"装货联系人"},
				// 	{key:"case_link_tel",cname:"装货联系电话"},
				// 	{key:"cargo_name",cname:"货物名称"}, 
				// 	{key:"aim_prov",cname:"到货地省份"},
				// 	{key:"aim_city",cname:"到货地城市"},
				// 	{key:"aim_county",cname:"到货地县区"},
				// 	{key:"aim_addr_info",cname:"到货地详细地址"},
				// 	{key:"aim_link_man",cname:"到货地联系人"},
				// 	{key:"aim_link_tel",cname:"到货地联系电话"},
				// 	{key:"cargo_type",cname:"货物类型"},
				// 	{key:"distance",cname:"运输距离"},
				// 	{key:"freight_total",cname:"运费"},
				// 	{key:"service_charge",cname:"服务费"},
				// 	{key:"give_truck_owner_total",cname:"结算给车队长"},
				// 	{key:"give_driver_total",cname:"结算给司机"},
				// 	{key:"payed_total_of_out_sc",cname:"已支付"},
				// 	{key:"truck_owner_settled_total",cname:"已结(车队长)"},
				// 	{key:"need_service_total",cname:"应付总服务费"},
				// 	{key:"driver_settled_total",cname:"已结(司机)"},
				// 	{key:"settled_time_text",cname:"结算完成时间"},
				// 	{key:"tord_upld_time_text",cname:"运单上报时间"},
				// 	{key:"outcash_upld_time_text",cname:"出金上报时间"},
				// 	{key:"match_way_text",cname:"匹配方式"},
				// 	{key:"status_text",cname:"运单状态"},
				// 	{key:"lock_status_text",cname:"锁定状态"},
				// 	{key:"payed_status_text",cname:"支付状态"},
				// 	{key:"invoiced_status_text",cname:"开票状态"},
				// 	{key:"settled_status_text",cname:"结算状态"},
				// 	{key:"wlhy_sdk_status_text",cname:"部SDK上报状态"},
				// 	{key:"upl_status_text",cname:"省监管上报状态"},
				// 	{key:"upl_aim",cname:"省监管上报节点"},
				// 	{key:"settlement_type_text",cname:"结算方式"},
				// 	{key:"is_need_return_text",cname:"是否回程"},
				// 	{key:"shipper_tel",cname:"货主电话"},
				// 	{key:"shipper_name",cname:"货主姓名"},
				// 	{key:"shipper_company_name",cname:"货主公司"},
				// 	{key:"truck_plate_num",cname:"车牌号"},
					
					
				// 	{key:"cargo_weight_start",cname:"发车重量(吨)"},
				// 	{key:"cargo_weight_arrived",cname:"到货重量(吨)"},
				// 	// {key:"file_num",cname:"磅单编号"},
				// 	{key:"cargo_volume",cname:"货物体积(方)"},
				// 	{key:"service_total",cname:"已付总服务费"},
				// 	{key:"invoice_total",cname:"可开票总金额(元)"},
					
				// 	{key:"calc_mode_text",cname:"计算方式"},
				// 	{key:"freight_allocation_mode_text",cname:"运费分配方式"},
				// 	{key:"payed_total_of_all",cname:"已付(含服务费)"},
				// 	{key:"invoiced_total",cname:"已开票(含服务费)"},
				// 	{key:"invoiced_total_of_out_sc",cname:"已开票(不含服务费)"},
				// 	{key:"mark",cname:"备注"},
				// 	{key:"invoice_mark",cname:"发票备注"},
				// 	{key:"system_profit",cname:"公司利润"},
				// ],str="";
				// for(var a in shunxu){
				// 	str+=shunxu[a].cname+",";
				// }
				// str+="\n";
				// for(var i=0;i<this.buf.length;i++){
				// 	for(var a in shunxu){
				// 		if(this.isNumeric(this.buf[i][shunxu[a].key])){
				// 			str+=`${this.buf[i][shunxu[a].key]}\t,`;
				// 		}else{
				// 			str+=`${this.buf[i][shunxu[a].key]},`;
				// 		}
						
				// 	}
				// 	str+='\n';
				
				// }
				// str = "data:text/xlsx;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				// //生成下载链接
				// let link = document.createElement('a');
				// link.href = str;

				// //对下载的文件命名
				// link.download = "运单列表.xlsx";

				// //下载
				// document.body.appendChild(link);
				// link.click();
				// document.body.removeChild(link);
			},
			isNumeric(val) {
				return !isNaN(parseFloat(val));
			},
			//读取
			read(){
				var p = this.p
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						num:this.num,
						p:p,
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						is_get_truck_info:1,
						is_get_tord_set:1,
						...this.rull,
					},
					callback:(data)=>{
						this.p ++
						if(data.list.length ==0){
							this.p = 1
							this.read()
							return
						}
						//预处理
						for(let item of data.list){
							//发货时间
							item.cargo_creat_time_text=this.$my.other.totime(item.cargo_creat_time);

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							

							//公司利润
							if(item.shipper_info.tel =='18915059081'){
								item.system_profit = item.cost_info.system_profit
							}else{
								item.system_profit = ''
							}
							
							//发车时间
							if(item.start_time>0){//已发车
								item.start_time_text=this.$my.other.totime(item.start_time);
							}else item.start_time_text="未发车";
							
							//到货时间
							if(item.arrived_time>0){//已到货
								item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							}else item.arrived_time_text="未到货";

							//支付完成时间
							if(item.payed_time>0){
								item.payed_time_text=this.$my.other.totime(item.payed_time);
							}else item.payed_time_text="未完成";

							//开票完成时间
							if(item.invoiced_time>0){
								item.invoiced_time_text=this.$my.other.totime(item.invoiced_time);
							}else item.invoiced_time_text="未完成";

							//结算完成时间
							if(item.settled_time>0){
								item.settled_time_text=this.$my.other.totime(item.settled_time);
							}else item.settled_time_text="未完成";

							//运单上报时间
							if(item.tord_upld_time>0){
								item.tord_upld_time_text=this.$my.other.totime(item.tord_upld_time);
							}else item.tord_upld_time_text="未上报";

							//运单出金上报时间
							if(item.outcash_upld_time>0){
								item.outcash_upld_time_text=this.$my.other.totime(item.outcash_upld_time);
							}else item.outcash_upld_time_text="未上报";

							//匹配方式
							switch(item.match_way){
								case '1':item.match_way_text="收款人抢单";break;
								case '2':item.match_way_text="指定后收款人接单";break;
								case '3':item.match_way_text="收款人竞价中标";break;
								case '4':item.match_way_text="收款人扫码接单";break;
								case '5':item.match_way_text="网点线下匹配";break;
								case '6':item.match_way_text="合作方匹配";break;
								case '7':item.match_way_text="后台匹配";break;
								case '8':item.match_way_text="后台添加";break;
							}

							//运单状态
							switch(item.status){
								case '1':item.status_text="待发车";break;
								case '2':item.status_text="运输中";break;
								case '3':item.status_text="已到货";break;
							}

							//锁定状态
							switch(item.lock_status){
								case '1':item.lock_status_text="已锁定";break;
								case '2':item.lock_status_text="未锁定";break;
							}

							//运费支付状态
							switch(item.payed_status){
								case '1':item.payed_status_text="未支付";break;
								case '2':item.payed_status_text="部分支付";break;
								case '3':item.payed_status_text="支付完毕";break;
							}

							//结算方式
							switch(item.invoiced_status){
								case '1':item.invoiced_status_text="未开票";break;
								case '2':item.invoiced_status_text="部分开票";break;
								case '3':item.invoiced_status_text="开票完成";break;
							}

							//运费结算状态
							switch(item.settled_status){
								case '1':item.settled_status_text="未结算";break;
								case '2':item.settled_status_text="部分结算";break;
								case '3':item.settled_status_text="结算完毕";break;
							}

							//部监管平台上报状态(1:未上报,2:发车已上报,3:到货已上报)
							switch(item.wlhy_sdk_status){
								case '1':item.wlhy_sdk_status_text="未上报";break;
								case '2':item.wlhy_sdk_status_text="发车已上报";break;
								case '3':item.wlhy_sdk_status_text="到货已上报";break;
							}

							//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
							switch(item.upl_status){
								case '1':item.upl_status_text="未上报";break;
								case '2':item.upl_status_text="运单上报成功";break;
								case '3':item.upl_status_text="运单上报失败";break;
								case '4':item.upl_status_text="流水上报成功";break;
								case '5':item.upl_status_text="流水上报失败";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//取出货主数据
							item.shipper_tel=item.shipper_info.tel
							item.shipper_name=item.shipper_info.name
							item.shipper_company_name=item.shipper_company_info.name

							//取出收款人数据
							item.truck_owner_tel=item.truck_owner_info.tel
							item.truck_owner_name=item.truck_owner_info.name

							//取出司机数据
							item.driver_tel=item.driver_info.tel
							item.driver_name=item.driver_info.name

							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";

							//装货地其他数据(json)
							item.case_other_obj=JSON.parse(item.case_other);
							item.case_addr_info=item.case_other_obj.addr_info

							//卸货地其他数据(json)
							item.aim_other_obj = item.aim_other.replace(/\\/g, '');
							// item.aim_other_obj=JSON.parse(item.aim_other);
							item.aim_addr_info=item.aim_other_obj.addr_info
							
							//运费
							item.freight_total=item.cost_info.freight_total;

							//计算方式
							switch(item.cost_info.calc_mode){
								case '1':
									switch(item.set_info.extra_service_charge_type){
										case '1':item.calc_mode_text=`服务费率模式(除法)(${item.set_info.extra_service_charge_rate}个点)`;break;
										case '2':item.calc_mode_text="服务费率模式(乘法)";break;
									}
									break;
								case '2':item.calc_mode_text="固定金额模式";break;
							}

							//应结算
							switch(item.cost_info.freight_allocation_mode){//运费分配方式
								case '1':item.freight_allocation_mode_text="司机优先";break;
								case '2':item.freight_allocation_mode_text="车队长优先";break;
								case '2':item.freight_allocation_mode_text="等比例分配";break;
							}
							item.give_truck_owner_total=item.cost_info.give_truck_owner_total;//应结(车队长)
							item.give_driver_total=item.cost_info.give_driver_total;//应结(司机)

							//已付金额
							item.payed_total_of_all=item.cost_info.payed_total_of_all;//含服务费
							item.payed_total_of_out_sc=item.cost_info.payed_total_of_out_sc;//不含服务费

							//已开票金额
							item.invoiced_total=item.cost_info.invoiced_total;//含服务费
							item.invoiced_total_of_out_sc=item.cost_info.invoiced_total_of_out_sc;//不含服务费

							//已结算金额
							item.truck_owner_settled_total=item.cost_info.truck_owner_settled_total;//已结算(车队长)
							item.driver_settled_total=item.cost_info.driver_settled_total;//已结算(司机)

							item.service_charge = item.cost_info.service_charge

							item.id_card_num = item.driver_info.id_card_num

							this.xlsx_list.push({
								id: item.id,
								out_trade_num: item.out_trade_num,
								truck_tord_num: item.truck_tord_num,
								bl_num: item.bl_num,
								group1_num: item.group1_num,
								cargo_creat_time_text: item.cargo_creat_time_text,
								creat_time_text: item.creat_time_text,
								start_time_text: item.start_time_text,
								arrived_time_text: item.arrived_time_text,
								payed_time_text: item.payed_time_text,
								invoiced_time_text: item.invoiced_time_text,
								truck_owner_tel: item.truck_owner_tel,
								truck_owner_name: item.truck_owner_name,
								driver_tel: item.driver_tel,
								driver_name: item.driver_name,
								id_card_num: item.id_card_num,
								case_prov: item.case_prov,
								case_city: item.case_city,
								case_county: item.case_county,
								case_addr_info: item.case_addr_info,
								case_link_man: item.case_link_man,
								case_link_tel: item.case_link_tel,
								cargo_name: item.cargo_name,
								aim_prov: item.aim_prov,
								aim_city: item.aim_city,
								aim_county: item.aim_county,
								aim_addr_info: item.aim_addr_info,
								aim_link_man: item.aim_link_man,
								aim_link_tel: item.aim_link_tel,

								cargo_type: item.cargo_type,
								distance: item.distance,
								freight_total: item.freight_total,
								service_charge: item.service_charge,
								give_truck_owner_total: item.give_truck_owner_total,
								give_driver_total: item.give_driver_total,
								payed_total_of_out_sc: item.payed_total_of_out_sc,
								truck_owner_settled_total: item.truck_owner_settled_total,
								need_service_total: item.need_service_total,
								driver_settled_total: item.driver_settled_total,
								settled_time_text: item.settled_time_text,
								tord_upld_time_text: item.tord_upld_time_text,
								outcash_upld_time_text: item.outcash_upld_time_text,
								match_way_text: item.match_way_text,
								status_text: item.status_text,
								lock_status_text: item.lock_status_text,
								payed_status_text: item.payed_status_text,
								invoiced_status_text: item.invoiced_status_text,
								settled_status_text: item.settled_status_text,

								wlhy_sdk_status_text: item.wlhy_sdk_status_text,
								upl_status_text: item.upl_status_text,
								upl_aim: item.upl_aim,
								settlement_type_text: item.settlement_type_text,
								is_need_return_text: item.is_need_return_text,
								shipper_tel: item.shipper_tel,
								shipper_name: item.shipper_name,
								shipper_company_name: item.shipper_company_name,
								truck_plate_num: item.truck_plate_num,
								cargo_weight_start: item.cargo_weight_start,
								cargo_weight_arrived: item.cargo_weight_arrived,
								file_num: item.file_num,
								cargo_volume: item.cargo_volume,
								service_total: item.service_total,
								invoice_total: item.invoice_total,
								calc_mode_text: item.calc_mode_text,
								freight_allocation_mode_text: item.freight_allocation_mode_text,
								payed_total_of_all: item.payed_total_of_all,
								invoiced_total: item.invoiced_total,
								invoiced_total_of_out_sc: item.invoiced_total_of_out_sc,
								cname: item.cname,
								invoice_mark: item.invoice_mark,
								system_profit: item.system_profit,
								insure_total: item.cost_info.insure_total,
								goods_total: item.cost_info.goods_total,
							})
						}

						//取出最后一个id
						if(data.list[data.list.length-1]){
                           this.id_max=data.list[data.list.length-1].id
						}

						//置入缓存
						this.buf=this.buf.concat(data.list)

						this.readed=this.buf.length
						
						//是否继续
						if(this.readed<this.max){//没有达到最大数量则继续读取

							this.read();
						
						}else{//达到最大数量则开始导出

							this.make_csv()
						}
					}
				})
			},

			//启动
			start(){

				//读取总数量
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						only_num:1,
						...this.rull,
					},
					callback:(data)=>{

						//如果总数量为0
						if(data.max==0){
							this.$my.other.msg({
								type:"warning",
								str:'该条件下数据为空'
							});
							return;
						
						//置入总数量
						}else this.max=data.max;

						//已读取数据
						this.buf=[];

						//开始读取
						this.read();
					}
				})
			},
		}
	}
</script>