<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="运单id">
				<el-input class="el_input" v-model="form.id" placeholder="id搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路编号">
				<el-input class="el_input" v-model="form.bl_num" placeholder="线路编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="非货主手机">
				<el-input class="el_input" v-model="form.shipper_tel_not" placeholder="非货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主公司">
				<el-input class="el_input" v-model="form.shipper_company_name" placeholder="货主公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人名称">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="车牌号搜索" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="大区搜索">
				<el-input class="el_input" v-model="form.area_id" placeholder="大区名称搜索" clearable></el-input>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="销售电话">
				<el-input class="el_input" v-model="form.saler_tel" placeholder="销售电话搜索" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未发车" value="1"></el-option>
					<el-option label="已发车" value="2"></el-option>
					<el-option label="已到货" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.i_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未开票" value="1"></el-option>
					<el-option label="开票中" value="2"></el-option>
					<el-option label="已开票" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settled_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未结算" value="1"></el-option>
					<el-option label="部分结算" value="2"></el-option>
					<el-option label="结算完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报状态">
				<el-select class="el_input" v-model="form.upl_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上报" value="1"></el-option>
					<el-option label="运单上报成功" value="2"></el-option>
					<el-option label="运单上报失败" value="3"></el-option>
					<el-option label="流水上报成功" value="4"></el-option>
					<el-option label="流水上报失败" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单删除">
				<el-select class="el_input" v-model="form.is_show" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="待审核" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运费设置">
				<el-select class="el_input" v-model="form.is_set_freight" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="轨迹校验">
				<el-select class="el_input" v-model="form.check_act_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="验证通过" value="1"></el-option>
					<el-option label="未验证" value="2"></el-option>
					<el-option label="验证失败" value="3"></el-option>
					<el-option label="无轨迹" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="回单上传">
				<el-select class="el_input" v-model="form.voucher_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="已上传" value="1"></el-option>
					<el-option label="未上传" value="2"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="etc开票">
				<el-select class="el_input" v-model="form.etc_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未开票" value="1"></el-option>
					<el-option label="已开票" value="2"></el-option>
					<el-option label="开票失败" value="3"></el-option>
				</el-select>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="线路搜索" >
				<el-autocomplete
				class="inline-input"
				v-model="form.bl_name"
				:fetch-suggestions="querySearch"
				placeholder="线路名称"
				@focus="bl_focus"
				></el-autocomplete>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="项目名称">
				<el-input class="el_input" v-model="form.item_name" placeholder="项目名称搜索" clearable></el-input>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="省监管上报">
				<el-select class="el_input" v-model="form.logink_up_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上传" value="1"></el-option>
					<el-option label="一次已传" value="2"></el-option>
					<el-option label="二次已传" value="3"></el-option>
					<el-option label="车辆已传" value="4"></el-option>
					<el-option label="司机已传" value="5"></el-option>
					<el-option label="承运人已传" value="6"></el-option>
					<el-option label="三次已传" value="7"></el-option>
					<el-option label="流水已传" value="8"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="保障服务费">
				<el-select class="el_input" v-model="form.is_set_insure" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="有无发邮件">
				<el-select class="el_input" v-model="form.insure_status" clearable>
					<el-option label="不限" value="3"></el-option>
					<el-option label="已发送" value="1"></el-option>
					<el-option label="未发送" value="2"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item elfrom_item" label="发到时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="发车日期"
					end-placeholder="到货日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item> -->
			<el-form-item class="el_form_item elfrom_item" label="开票时间">
				<div class="block">
				<el-date-picker
					v-model="invoice_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="建单时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr1"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<!-- <el-form-item class="el_form_item elfrom_item" label="邮件发送时间">
				<div class="block">
				<el-date-picker
					v-model="insure_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="监管上报时间">
				<div class="block">
				<el-date-picker
					v-model="logink_up_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item> -->
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_form_open">更多条件</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="batch_pass(0,'tord_check_pass',1)">批量通过</el-button>
				<el-button type="primary" @click="batch_lock_open_msg(0,'tord_check_unpass',2)">批量打回</el-button>
				<el-button type="primary" @click="batch_pass(0,'tord_check_reset',3)">批量回到待审核</el-button>
				<el-button type="primary" @click="batch_check_time_agent_ord(0)">批量运单查重</el-button>
				<!-- <el-button type="primary"  @click="set_truck_owner">设置收款人</el-button> -->
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总计:{{total}}元</span>
			<span>总运费:{{list.total_freight}}元</span>
			<span v-if="list.total_service>0">服务费{{list.total_service}}元</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="tords_list_choose" >
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="运单id" width="100px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单/组别/上游单号" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">线路{{scope.row.bl_num}}</div>
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:"无"}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:"无"}}</div>
						<div v-if="scope.row.item_name">项目名称:{{scope.row.item_name}}</div>
						<div class="tab_line_item" v-if="scope.row.tax_num">发票号码：{{scope.row.tax_num}}</div>
						<div class="tab_line_item" v-if="scope.row.tax_code">发票代码：{{scope.row.tax_code}}</div>
						<div class="tab_line_item" v-if="scope.row.bl_info.consignee">收货方:{{scope.row.bl_info.consignee}}</div>
						<!-- <div class="tab_line_item" v-if="scope.row.consigneeid">收货方信用代码:{{scope.row.consigneeid}}</div> -->
						<div class="tab_line_item" v-if="scope.row.bl_info.seller_name">销售方:{{scope.row.bl_info.seller_name}}</div>
						<!-- <div class="tab_line_item" v-if="scope.row.seller_code">销售方信用代码:{{scope.row.seller_code}}</div> -->
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}/{{scope.row.case_other_obj.addr_info?scope.row.case_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}/{{scope.row.aim_other_obj.addr_info?scope.row.aim_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">发车日期:{{scope.row.case_other_obj.start_time}}{{(scope.row.case_other_obj.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_type}}/{{scope.row.cargo_name}}</div>
						<div class="tab_line_item">发：{{parseFloat(scope.row.cargo_weight_start)==0?scope.row.cargo_weight +'吨':scope.row.cargo_weight_start+"吨"}}</div>
						<div class="tab_line_item">到：{{parseFloat(scope.row.cargo_weight_arrived)==0?scope.row.cargo_weight+'吨' :scope.row.cargo_weight_arrived+"吨"}}</div>
						<div class="tab_line_item">重量：{{scope.row.cargo_weight+'吨'}}</div>
						<div class="tab_line_item">体积：{{scope.row.cargo_volume==0?"无体积":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">{{scope.row.settlement_type_text}}/{{scope.row.is_need_return_text}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/服务费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">总运费:{{scope.row.cost_info.freight_total_text}}</div>
						<div class="tab_line_item">司机:{{scope.row.cost_info.give_driver_total}}</div>
						<div class="tab_line_item">收款人:{{scope.row.cost_info.give_truck_owner_total}}</div>
						<div class="tab_line_item">服务费:{{scope.row.cost_info.service_charge}}</div>
						<div class="tab_line_item">保障服务费:{{scope.row.cost_info.insure_total}}</div>
						<div class="tab_line_item">已支付服务费:{{scope.row.service_total}}</div>
						<div class="tab_line_item">预计开票金额:{{scope.row.should_invoice_total}}</div>
						<div class="tab_line_item">已开票金额:{{scope.row.cost_info.invoiced_total}}</div>
						<div class="tab_line_item">
							{{scope.row.payed_status_text}} 
							<el-button class="btn_left" @click="tord_payed_list(scope.row,'tord_pay_list')" size="mini" type="text">支付记录</el-button>
						</div>
						<div class="tab_line_item">
							{{scope.row.settled_status_text}} 
							<el-button class="btn_left" @click="tord_payed_list(scope.row,'tord_pay_list')" size="mini" type="text">结算记录</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}}/{{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_company_info.name}}</div>		
					</template>
				</el-table-column>
				<el-table-column label="收款人/司机/货车信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.truck_plate_num}}{{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
						<div class="tab_line_item">
							{{scope.row.lock_status_text}} 
							<el-button @click="tord_unlock(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==1">解锁</el-button>
							<el-button @click="tord_lock_open(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==2" style="color:red">锁定</el-button>
						</div>
						<div class="tab_line_item">{{scope.row.upl_text}}</div>
						<div class="tab_line_item" style="color:red;font-size:16px;font-weight: bolder;" v-if="scope.row.is_show ==2">已删除</div>
						<div class="tab_line_item">{{scope.row.check_act_status_text}}</div>
						<div class="tab_line_item" style="color:red">{{scope.row.text}}</div>
						<div class="tab_line_item" v-if="scope.row.insure_status">邮件状态:{{scope.row.insure_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="上报状态" width="80px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tax_upl_status_text}}</div>
						<div class="tab_line_item">省:{{scope.row.logink_up_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="时效信息" width="155px">
					<template slot-scope="scope">
						<div class="tab_line_item">接:{{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">发:{{scope.row.start_time_text}}</div>
						<div class="tab_line_item">到:{{scope.row.arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="132">
					<template slot-scope="scope">
					   <el-button  @click="tord_check_pass(scope.row,'tord_check_pass',1)"  class="btn_left" size="mini" type="text" >审核通过</el-button>
					    <el-button  @click="tord_check_pass(scope.row,'tord_check_unpass',2)"  class="btn_left" size="mini" type="text" >审核打回</el-button>
						<el-button  @click="tord_check_pass(scope.row,'tord_check_reset',3)"  class="btn_left" size="mini" type="text" >回到待审核</el-button>
						<el-button  @click="check_time_agent_ord(scope.row)"  class="btn_left" size="mini" type="text" >运单查重</el-button>
						
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-backtop
		target=".page"
		:bottom="100"
		:visibility-height="50"
		></el-backtop>
		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 100, 200, 500]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 搜索表单 -->
		<serForm 
			:is_show="ser_form_is_show" 
			:form="form" 
			@sub="ser_form_sub"
			@close="ser_para_clear"
		></serForm>

		<!-- 导出搜索结果 -->
		<serRelExport 
			:is_show="tord_export.is_show" 
			:rull="tord_export.rull" 
			@close="tord_export_close" 
		></serRelExport>

	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import serForm from './ser_form.vue'
	import voucher from '../../voucher/voucher_before_end.vue'
	import serRelExport from './ser_rel_export.vue'
	import { Loading } from 'element-ui';
	export default {
		components:{
			serForm,
			voucher,
			serRelExport,
		},
		data() {
			return {
				date_value:'',
				invoice_time:'',
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				total:'',//总计费用
				num_a:1,
				//搜索界面是否打开
				ser_form_is_show:0,
				invoice_mark:"",//发票备注
				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					shipper_tel:'',//货主手机号
					shipper_tel_not:'',//非货主手机号
					shipper_company_name:'',//货主公司名称
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					status:'',//状态
					cargo_name:'',//货物名称
					payed_status:'',//支付状态
					settled_status:'',//结算状态
					upl_status:'',//上报状态
					is_set_freight:'',//运费设置
					bl_name:'',//线路名称搜索
					i_status:'',//开票状态
					area_id:'',//大区搜索
					saler_tel:'',//销售电话搜索
					check_act_status:'',//验证状态搜索
					start_time_start:0,
					arrived_time_end:0,

					invoiced_time_start:'', //开票开始时间
                    invoiced_time_end:'',//开票结束时间

					is_show:'',//运单申请删除

					creat_time_arr:[],//建单时间

					insure_time_arr:[],//邮件发送时间

					logink_up_time_arr:[],//监管上报时间

					id:'',
					voucher_status:'',//回单是否上传

					etc_status:'',//etc开票状态

					item_name:'',//项目名称

					logink_up_status:'',

					is_set_insure:'',//保障服务费
					
					insure_status:'',//有无发送保险  有无购买保险
				},
				creat_time_arr1:'',
				insure_time_arr:'',//邮件发送时间
				logink_up_time_arr:'',
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight:'',//总金额
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},


				//导出搜索结果界面
				tord_export:{
					is_show:0,
					rull:{}
				},
				
				//运单参数界面
				tord_set:{
					is_show:0,
					truck_tord_num:'',
				},
				restaurants: [],
				
				//选中的运单
				tords_choosed:[],

				num:0,
				batch_mark_show:false,
				batch_lock_msg:'',//批量审核打回原因
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {
			//单个
			check_time_agent_ord(item){
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'check_time_agent_ord',
						truck_tord_num:[item.truck_tord_num]
					},callback:(data)=>{
						this.$my.other.msg({
							type:"success",
							str:'操作完成'
						});
					}
				})
			},
			//批量运单查重
			batch_check_time_agent_ord(n){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'操作完成'
					});
					//刷新本页
					this.get_page_data()
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'admin',
						ctr:'check_time_agent_ord',
						truck_tord_num:choosed[n].truck_tord_num,
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							this.close_loading()
							return
						}
						n++
						this.batch_check_time_agent_ord(n)
					},
				});
			},
			batch_lock_open_msg(n,ctr,check_status){
				this.$prompt('请输入驳回原因', {
					}).then(({ value }) => {
						this.batch_lock_msg = value
						this.batch_unpass(n,ctr,check_status)  
					}).catch(() => {
						 
				});
			},
		   //批量打回
			batch_unpass(n,ctr,check_status){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'操作完成'
					});
					//刷新本页
					this.get_page_data()
					this.batch_lock_msg = ''
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr,
						check_status,
						check_reason:this.batch_lock_msg,
						truck_tord_nums:[choosed[n].truck_tord_num],
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							this.close_loading()
							return
						}
						n++
						this.batch_pass(n)
					},
				});
			},
			//批量操作
			batch_pass(n,ctr,check_status){
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				Loading.service(this.options);
				//关闭等待特效
				if(n==this.tords_choosed.length){
					this.close_loading()
					this.$my.other.msg({
						type:"success",
						str:'操作完成'
					});
					//刷新本页
					this.get_page_data()
					return
				}
				//提交
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr,
						truck_tord_nums:[choosed[n].truck_tord_num],
						check_status
					},
					callback:(data)=>{
						if(data.code!=0){
							this.$my.other.msg({
								type:"info",
								str:data.error_info
							});
							this.close_loading()
							return
						}
						n++
						this.batch_pass(n)
					},
				});
			},
			//单个操作
			tord_check_pass(item,ctr,check_status){
				if(ctr=='tord_check_unpass'){
					this.$prompt('请输入打回原因', {
						}).then(({ value }) => {
							console.log(value)
							this.$my.net.req({
								data:{
									mod:'truck_tord_real_time',
									ctr,
									check_status,
									check_reason:value,
									truck_tord_nums:[item.truck_tord_num]
								},callback:(data)=>{
									this.$my.other.msg({
										type:"success",
										str:'操作完成'
									});
								}
							})
						}).catch(() => {
							
					});

					return
				}
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr,
						check_status,
						truck_tord_nums:[item.truck_tord_num]
					},callback:(data)=>{
						console.log(data)
					}
				})
			},
			//关闭下载等待特效
			close_loading(){
				let loadingInstance = Loading.service(this.options);
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
				loadingInstance.close();
				});
			},
			//勾选
			tords_list_choose(event){
				//置入
				this.tords_choosed=event
			},
			
			
			bl_focus(){
				//格式判断
				if(!this.$my.check.is_tel(this.form.shipper_tel)){
					this.$my.other.msg({
						type:"warning",
						str:'货主电话号码格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'app_user_list_by_admin',
						tel:this.form.shipper_tel
					},
					callback:(data)=>{
						data=data.list[0];
						if(data.name=='未实名'){
							this.$my.other.msg({
								type:"warning",
								str:'此用户未完成实名认证'
							});
							return;
						}
						if(!data.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'缺少货主信息'
							});
							return;
						}
						//去服务器读取线路
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_real_time_bl_list_admin',
								shipper_num:data.user_num,
							},
							callback:(data)=>{
								var bl_list = []
								//遍历
								for(var item of data.list){

									//结算方式
									var settlement_type_text=""
									switch(item.is_need_return){
										case '1':settlement_type_text="按吨数结算";break;
										case '2':settlement_type_text="按方数结算";break;
										case '3':settlement_type_text="按趟结算";break;
									}

									//是否是双边
									var is_need_return_text=""
									switch(item.is_need_return){
										case '1':is_need_return_text="双边";break;
										case '2':is_need_return_text="单边";break;
									}

									//装货地其他数据
									item.case_other_obj=JSON.parse(item.case_other);

									//卸货地其他数据
									item.aim_other_obj=JSON.parse(item.aim_other);

									//汇集路线数据
									item.info_text=item.case_prov+'/'+item.case_city+'/'+item.case_county+' ---- '+item.aim_prov+'/'+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')'
									bl_list.push({
										value:item.bl_name,
										address:item.bl_name
									})
								}
								this.restaurants = bl_list
							}
						})
					}
				});
			},
		   querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			
			//更多条件
			ser_form_open(){//打开

				this.ser_form_is_show++
			},
			ser_form_sub(obj){//提交

				//置入表单数据
				for(var key in obj){

					if(!obj[key]){
						continue;
					}

					this.form[key]=obj[key];

					//特殊处理
					switch(key){

						case 'cargo_creat_time'://货源创建时间
							this.form.cargo_creat_time_arr=[
								(obj.cargo_creat_time[0].getTime() / 1000).toFixed(0),
								(obj.cargo_creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'creat_time'://成交时间(运单创建时间)
							this.form.creat_time_arr=[
								(obj.creat_time[0].getTime() / 1000).toFixed(0),
								(obj.creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'start_time'://发车时间
							this.form.start_time_arr=[
								(obj.start_time[0].getTime() / 1000).toFixed(0),
								(obj.start_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'arrived_time'://到货时间
							this.form.arrived_time_arr=[
								(obj.arrived_time[0].getTime() / 1000).toFixed(0),
								(obj.arrived_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'payed_time'://支付完成时间
							this.form.payed_time_arr=[
								(obj.payed_time[0].getTime() / 1000).toFixed(0),
								(obj.payed_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'invoiced_time'://开票完成时间
							this.form.invoiced_time_arr=[
								(obj.invoiced_time[0].getTime() / 1000).toFixed(0),
								(obj.invoiced_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'settled_time'://结算完成时间
							this.form.settled_time_arr=[
								(obj.settled_time[0].getTime() / 1000).toFixed(0),
								(obj.settled_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'tord_upld_time'://运单上报时间
							this.form.tord_upld_time_arr=[
								(obj.tord_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.tord_upld_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'outcash_upld_time'://出金流水上报时间
							this.form.outcash_upld_time_arr=[
								(obj.outcash_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.outcash_upld_time[1].getTime() / 1000).toFixed(0),
							];break;
					}
				}

				//关闭页面
				this.ser_form_is_show=0

				//读取数据
				this.page_ser()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1
				if (this.date_value) {
					this.form.start_time_start = parseInt(this.date_value[0] / 1000);
					this.form.arrived_time_end= parseInt(this.date_value[1] / 1000);
				} else {
					this.form.start_time_start = "";
					this.form.arrived_time_end = "";
				}
				if (this.invoice_time) {
					this.form.invoiced_time_start = parseInt(this.invoice_time[0] / 1000);
					this.form.invoiced_time_end= parseInt(this.invoice_time[1] / 1000);
				} else {
					this.form.invoiced_time_start = "";
					this.form.invoiced_time_end = "";
				}
				if(this.creat_time_arr1){
					this.form.creat_time_arr[0] = parseInt(this.creat_time_arr1[0] / 1000);
				    this.form.creat_time_arr[1] = parseInt(this.creat_time_arr1[1] / 1000);
				}else{
					this.form.creat_time_arr = []
				}
				if(this.insure_time_arr){
					this.form.insure_time_arr[0] = parseInt(this.insure_time_arr[0] / 1000);
				    this.form.insure_time_arr[1] = parseInt(this.insure_time_arr[1] / 1000);
				}else{
					this.form.insure_time_arr = []
				}
				if(this.logink_up_time_arr){
					this.form.logink_up_time_arr[0] = parseInt(this.logink_up_time_arr[0] / 1000);
				    this.form.logink_up_time_arr[1] = parseInt(this.logink_up_time_arr[1] / 1000);
				}else{
					this.form.logink_up_time_arr = []
				}
				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(e){
				this.form={
					
					//运单编号相关
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号
					is_set_freight:'',
					//货主相关
					shipper_tel:'',//货主手机号
					shipper_tel_not:'',//非货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称
					area_id:'',//大区搜索
					saler_tel:'',//销售电话搜索
					//收款人/司机/车辆
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					bl_name:'', //线路名称
					check_act_status:'',//轨迹验证状态搜索
					//状态相关
					status:'',//状态(1:待发车,2:运输中,3:已到货)
					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)
					payed_status:'',//支付状态(1:未支付,2:部分支付,3:支付完毕)
					invoiced_status:'',//开票状态(1:未开票,2:部分开票,3:开票完毕)
					settled_status:'',//结算状态(1:未结算,2:部分结算,3:结算完毕)
					upl_status:'',//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
					upl_aim:'',//运单上报目标,上报后有值(类似:zhejiang;anhui....)
					voucher_status:'',//回单是否上传
					id:'',
					etc_status:'',

					item_name:'',//项目名称
					logink_up_status:'',
					is_set_insure:'',//保障服务费
					//货源数据相关
					cargo_name:'',//货物名称
					cargo_type:'',//货物类型
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式(1:收款人抢单,2:货主指定后收款人接单,3:收款人竞价,4:收款人扫码接单,5:网点线下匹配后报单,6:合作方协助匹配后报单,7:后台匹配,8:后台添加)
					outlets_num:'',//网点编号

					//货车类型相关
					need_truck_type:'',//租用货车类型
					need_truck_type2:'',//租用货车细分类型
					need_truck_types:null,//货车类型

					//发到货地相关
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					start_time_start:'',
					arrived_time_end:'',

					invoiced_time_start:'', //开票开始时间
                    invoiced_time_end:'',//开票结束时间

					//时效相关
					cargo_creat_time:null,//货源创建时间
					creat_time:null,//成交时间(运单创建时间)
					start_time:null,//发车时间
					arrived_time:null,//到货时间
					payed_time:null,//支付完成时间
					invoiced_time:null,//开票完成时间
					settled_time:null,//结算完成时间
					tord_upld_time:null,//运单上报时间
					outcash_upld_time:null,//出金流水上报时间

					is_show:'',

					creat_time_arr:[]
				}
				this.date_value=''
				this.invoice_time = ''
				if(e !=1){
					//读取
					this.get_page_data()
				}
			
				
			},

			//导出搜索结果
			tord_export_open(){//打开界面

				//置入条件
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},
			
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;
				console.log(this.form)
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						is_get_truck_info:1,
						is_get_tord_outlets:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						if(data.code ==0){
							//加载完毕
							this.list.loading=false

							//总数
							this.list.total=data.msg.max

							//总金额
							this.list.total_freight = data.msg.total.total_freight

							//总服务费
							this.list.total_service = data.msg.total.total_service

							//总计费用
							this.total = (Number(data.msg.total.total_freight) + Number(data.msg.total.total_service)).toFixed(2)
							//预处理
							for(let item of data.msg.list){
								item.cargo_weight = Number(item.cargo_weight).toFixed(2)
								//成交时间
								item.creat_time_text=this.$my.other.totime(item.creat_time);
								
								//发车时间
								if(item.start_time>0){//已发车
									item.start_time_text=this.$my.other.totime(item.start_time);
								}else item.start_time_text="未发车";
								
								//到货时间
								if(item.arrived_time>0){//已到货
									item.arrived_time_text=this.$my.other.totime(item.arrived_time);
								}else item.arrived_time_text="未到货";

								//装货联系人
								if(item.case_link_man){
									item.case_link_man_text=item.case_link_man;
								}else item.case_link_man_text="无";

								//装货联系电话
								if(item.case_link_tel){
									item.case_link_tel_text=item.case_link_tel;
								}else item.case_link_tel_text="无";

								//卸货联系人
								if(item.aim_link_man){
									item.aim_link_man_text=item.aim_link_man;
								}else item.aim_link_man_text="无";

								//卸货联系电话
								if(item.aim_link_tel){
									item.aim_link_tel_text=item.aim_link_tel;
								}else item.aim_link_tel_text="无";

								/* 装货地其他数据(json) */
								item.case_other_obj=JSON.parse(item.case_other);

								//装货时间
								if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

								}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

								}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

								}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

									item.case_other_obj.case_date="未设置";
								}

								//发车时间
								if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

								}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

								}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

								}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有
									
									item.case_other_obj.start_time="未设置";
								}

								//发车时间是否强制校验
								switch(item.case_other_obj.start_time_is_check){
									case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
									case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
								}

								//异常运单是否验证
								switch(item.check_act_status){
									case '1':item.check_act_status_text="已验证";break;
									case '2':item.check_act_status_text="未验证";break;
									case '2':item.check_act_status_text="验证失败";break;
								}
								if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
									item.case_other_obj.start_time_is_check_text=""
								}
								/* 卸货地其他数据(json) */
								item.aim_other_obj=JSON.parse(item.aim_other);
								
								//卸货时间
								if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

								}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

								}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

								}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有
									
									item.aim_other_obj.aim_date="未设置";
								}

								//运单状态
								switch(item.status){
									case '1':item.status_text="待发车";break;
									case '2':item.status_text="运输中";break;
									case '3':item.status_text="已到货";break;
								}

								//锁定状态
								switch(item.lock_status){
									case '1':item.lock_status_text="已锁定";break;
									case '2':item.lock_status_text="未锁定";break;
								}
								
								//上报状态
								switch(item.tax_upl_status){
									case '1':item.tax_upl_status_text="未同步";break;
									case '2':item.tax_upl_status_text="已同步尚未第一次上传";break;
									case '3':item.tax_upl_status_text="第一次已上传";break;
									case '4':item.tax_upl_status_text="一次失败";break;
									case '5':item.tax_upl_status_text="二次已上传";break;
									case '6':item.tax_upl_status_text="二次失败";break;
									case '7':item.tax_upl_status_text="三次已上传";break;
									case '8':item.tax_upl_status_text="三次失败";break;
									case '9':item.tax_upl_status_text="其他";break;
								}
								//省监管上报状态
								switch(item.logink_up_status){
									case '1':item.logink_up_status_text="未上传";break;
									case '2':item.logink_up_status_text="一次已传";break;
									case '3':item.logink_up_status_text="二次已传";break;
									case '4':item.logink_up_status_text="车辆已传";break;
									case '5':item.logink_up_status_text="司机已传";break;
									case '6':item.logink_up_status_text="承运人已传";break;
									case '7':item.logink_up_status_text="三次已传";break;
									case '8':item.logink_up_status_text="流水已传";break;
								}

								//邮件发送状态
								switch(item.insure_status){
									case '1':item.insure_status_text="邮件已发送";break;
									case '2':item.insure_status_text="邮件未发送";break;
								}
								//保障服务费
								switch(item.is_set_insure){
									case '2':item.is_set_insure_text="已设置";break;
									case '3':item.is_set_insure_text="未设置";break;
								}
								//结算方式
								switch(item.settlement_type){
									case '1':item.settlement_type_text="按吨数";break;
									case '2':item.settlement_type_text="按方数";break;
									case '3':item.settlement_type_text="按趟";break;
								}
								
								//是否回程
								switch(item.is_need_return){
									case '1':item.is_need_return_text="需要回程";break;
									case '2':item.is_need_return_text="不需要回程";break;
								}

								//匹配方式
								switch(item.match_way){
									case '1':item.match_way_text="收款人抢单";break;
									case '2':item.match_way_text="指定后收款人接单";break;
									case '3':item.match_way_text="收款人竞价中标";break;
									case '4':item.match_way_text="收款人扫码接单";break;
									case '5':item.match_way_text="网点线下匹配";break;
									case '6':item.match_way_text="合作方匹配";break;
									case '7':item.match_way_text="后台匹配";break;
									case '8':item.match_way_text="后台添加";break;
								}

								//应收总额
								if(item.cost_info.freight_total){
									item.cost_info.freight_total_text=item.cost_info.freight_total+" 元";
								}else item.cost_info.freight_total_text="未设置";

								//应付总额
								if(item.cost_info.give_carrier_total){
									item.cost_info.give_carrier_total_text=item.cost_info.give_carrier_total+" 元";
								}else item.cost_info.give_carrier_total_text="未设置";

								//已付总额
								item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+" 元"

								//运费支付状态
								switch(item.payed_status){
									case '1':item.payed_status_text="未支付";break;
									case '2':item.payed_status_text="部分支付";break;
									case '3':item.payed_status_text="支付完毕";break;
								}

								//运费结算状态
								switch(item.settled_status){
									case '1':item.settled_status_text="未结算";break;
									case '2':item.settled_status_text="部分结算";break;
									case '3':item.settled_status_text="结算完毕";break;
								}

								//货车类型
								item.truck_info.truck_type_name_text=`
									${item.truck_info.truck_type_name?'/'+item.truck_info.truck_type_name+'/':''}
									${item.truck_info.truck_type_name2?item.truck_info.truck_type_name2:''}
								`;

								item.text = ''
								//上报
								switch(item.upl_status){
									case '1':item.upl_text="未上报";break;
									case '2':item.upl_text="运单上报成功";break;
									case '3':item.upl_text="运单上报失败";break;
									case '4':item.upl_text="流水上报成功";break;
									case '5':item.upl_text="流水上报失败";break;
								}
								if(item.upl_aim)item.upl_text+=`(${this.logink_aim_list[item.upl_aim]})`
							}

							//渲染
							this.list.data=data.msg.list

						}else{
							this.$my.other.msg({
								str:data.error_info,
								type:'info'
							});
							this.list.loading=false
							this.list.data=[]
						}

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:1000px;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}
	/deep/.el-button--text{
		color: #409eff !important;
	}
	.btn_delete{
		color:red !important
	}
</style>